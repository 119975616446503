import { ChangeDetectionStrategy, Component, OnDestroy, inject } from "@angular/core";
import { NotificationsService } from "@e-tenant-hub/client-platform/shared/ui/responsive/notifications";
import { CookiesAcceptance, CookiesService } from "@e-tenant-hub/shared/rentals";
import { ResponsiveModalContainerService } from "@e-tenant-hub/shared/ui/responsive/modal-container";
import { CacheKeys, CacheStorageService, PreciseDate } from "@e-tenant-hub/shared/utils";
import { Subscription, catchError, of } from "rxjs";
import { CookiesModalModes } from "./cookies-modal.types";
import { CookiesSettingsSelection } from "./cookies-settings/cookies-settings.component";

@Component({
	selector: "cp-cookies-modal",
	templateUrl: "./cookies-modal.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiesModalComponent implements OnDestroy {
	private readonly cacheStorageService = inject(CacheStorageService);
	private readonly cookiesService = inject(CookiesService);
	private readonly modalContainerService = inject(ResponsiveModalContainerService);
	private readonly notificationsService = inject(NotificationsService);

	private mode: CookiesModalModes = CookiesModalModes.acceptance;
	private submitSubscription!: Subscription;

	get isAcceptanceView(): boolean {
		return this.mode === CookiesModalModes.acceptance;
	}

	get title(): string {
		switch (this.mode) {
			case CookiesModalModes.acceptance:
				return "l'espace locataire utilise des cookies";
			case CookiesModalModes.settings:
				return "gestion de vos préférences sur les cookies";
		}
	}

	constructor() {
		if (this.modalContainerService.getDialogData())
			this.mode = this.modalContainerService.getDialogData() as CookiesModalModes;
	}

	ngOnDestroy(): void {
		this.submitSubscription?.unsubscribe();
	}

	handleBtnAcceptAllClick(): void {
		this.setCookiesApproval(true, true);
	}

	handleBtnRefuseAllClick(): void {
		this.setCookiesApproval(false, false);
	}

	handleBtnSettingsSaveClick(value: CookiesSettingsSelection): void {
		this.setCookiesApproval(value.necessariesAccepted, value.googleAnalyticsAccepted);
	}

	handleBtnSettingsClick(): void {
		this.mode = CookiesModalModes.settings;
	}

	private setCookiesApproval(accepted: boolean, acceptedGa: boolean): void {
		const dateNow = new PreciseDate();
		const expirationDate = new PreciseDate().add(6, "months");

		const cookieAcceptance = {
			acceptedGA: acceptedGa,
			acceptedNecessaries: accepted,
			choice: accepted ? "ACCEPTED" : "REFUSED",
			choiceDate: dateNow,
			expirationDate: expirationDate,
			userAgent: window.navigator.userAgent,
		} as CookiesAcceptance;

		if (!accepted || !acceptedGa) {
			this.cookiesService.refuseCookieGa(expirationDate.valueOf());
		} else if (acceptedGa) {
			this.cookiesService.acceptCookieGa();
		}

		this.cacheStorageService.saveCache<CookiesAcceptance>(CacheKeys.APPROVAL_COOKIES_INFO, cookieAcceptance);
		this.submitSubscription = this.cookiesService
			.saveCookieAcceptance(cookieAcceptance)
			.pipe(
				catchError(() => {
					this.notificationsService.showErrorMessage(
						"Le changement de vos préférences a échoué, veuillez vérifier les informations saisies ou réessayer ultérieurement."
					);
					return of(undefined);
				})
			)
			.subscribe((response) => {
				if (response && response.result) {
					this.notificationsService.showConfirmationMessage("Votre préférences ont été prises en compte.");
					this.modalContainerService.closeDialog();
				}
			});
	}
}
