import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild, inject } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { NotificationsService } from "@e-tenant-hub/client-platform/shared/ui/responsive/notifications";
import { AuthService } from "@e-tenant-hub/shared/auth";
import {
	SolicitationCategories,
	SolicitationCommentTypes,
	SolicitationResponseItem,
	SolicitationScopes,
	SolicitationStatus,
	SolicitationStepRequest,
	SolicitationsService,
} from "@e-tenant-hub/shared/rentals";
import { ResponsiveModalContainerService } from "@e-tenant-hub/shared/ui/responsive/modal-container";
import { Colors, ScreenSizeService } from "@e-tenant-hub/shared/utils";
import { catchError, of } from "rxjs";

@Component({
	selector: "cp-solicitation-detail-modal",
	templateUrl: "./solicitation-detail-modal.component.html",
	styleUrl: "./solicitation-detail-modal.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SolicitationDetailModalComponent {
	@ViewChild("solicitationDetailContainer") solicitationDetailContainerRef?: ElementRef;
	@ViewChild("solicitationStepsContainer") solicitationStepsContainerRef?: ElementRef;

	readonly solicitationsService = inject(SolicitationsService);
	private readonly changeDetectorRef = inject(ChangeDetectorRef);
	private readonly formBuilder = inject(FormBuilder);
	private readonly modalContainerService = inject(ResponsiveModalContainerService);
	private readonly notificationsService = inject(NotificationsService);
	private readonly screenSizeService = inject(ScreenSizeService);
	private readonly authService = inject(AuthService);
	private readonly user = this.authService.user;

	data: SolicitationResponseItem;

	Colors = Colors;
	solicitationCategories = SolicitationCategories;
	solicitationCommentTypes = SolicitationCommentTypes;
	solicitationScopes = SolicitationScopes;
	solicitationStatus = SolicitationStatus;

	hasSpecificActionTag = false;

	commentMaxLength = 600;
	private commentDefaultValue = "";
	private commentTypeDefaultValue = SolicitationCommentTypes.informationRequest;

	forceFormSubmitButtonDisabled = false;

	solicitationStepForm = this.formBuilder.group({
		comment: [this.commentDefaultValue, Validators.maxLength(this.commentMaxLength)],
		commentType: [this.commentTypeDefaultValue],
	});

	get comment(): FormControl {
		return this.solicitationStepForm.get("comment") as FormControl;
	}
	get commentType(): FormControl {
		return this.solicitationStepForm.get("commentType") as FormControl;
	}

	get isFormSubmitButtonDisabled(): boolean {
		return this.forceFormSubmitButtonDisabled || this.isFormInvalidOrPristine();
	}

	get companyPhoneNumber(): string {
		return this.data.companyMobilePhone ? this.data.companyMobilePhone : this.data.companyOfficePhone;
	}

	constructor() {
		this.data = this.setData(this.modalContainerService.getDialogData() as SolicitationResponseItem);
	}

	onSubmit(solicitationId: string) {
		if (this.solicitationStepForm.invalid || this.isFormSubmitButtonDisabled || !this.data.canInteract) return;

		this.forceFormSubmitButtonDisabled = true;

		const request: SolicitationStepRequest = {
			comment: SolicitationCommentTypes.formRecord[this.commentType.value] ?? this.comment.value,
			rentalId: this.user.currentRentalId,
			thirdParty: this.user.thirdPartyId,
			type: this.commentType.value,
		};

		this.solicitationsService
			.addSolicitationStep(solicitationId, request)
			.pipe(
				catchError(() => {
					this.notificationsService.showErrorMessage(
						"Une erreur s’est produite lors de l’envoi de votre message"
					);
					this.forceFormSubmitButtonDisabled = false;
					return of(undefined);
				})
			)
			.subscribe((response) => {
				if (response) {
					this.modalContainerService.reloadData(response);
					this.data = this.setData(response);
					this.changeDetectorRef.markForCheck();
					this.solicitationsService.reloadSolicitations();

					this.removeSpecificActionTag();
					this.scrollToSolicitationStepsContainerTop();
					this.forceFormSubmitButtonDisabled = false;
				}
			});
	}

	showCommentDeactivationReason(): void {
		if (this.comment.enabled) return;

		if (!this.data.canInteract) {
			return this.notificationsService.showInformationMessage(
				"Pour des raisons de confidentialité, l'envoi de commentaire est désactivé."
			);
		}

		if (this.data.isPublic) {
			return this.notificationsService.showInformationMessage(
				"L'envoi de commentaire est désactivé pour les demandes concernant les parties communes."
			);
		}
	}

	setCancellationAction(): void {
		this.setCustomComment(SolicitationCommentTypes.closureRequest);
	}

	setReminderAction(): void {
		this.setCustomComment(SolicitationCommentTypes.reminder);
	}

	removeSpecificActionTag(): void {
		this.hasSpecificActionTag = false;
		this.resetFormWithDefaultValues();
	}

	private setData(data: SolicitationResponseItem): SolicitationResponseItem {
		if (!data.isCommentAllowed) {
			this.comment.disable();
		}

		return data;
	}

	private isFormInvalidOrPristine(): boolean {
		return this.solicitationStepForm.invalid || this.solicitationStepForm.pristine || !this.comment.value;
	}

	private scrollToSolicitationStepsContainerTop(): void {
		if (this.screenSizeService.isSmallScreen()) {
			return this.solicitationDetailContainerRef?.nativeElement.scrollTo({ top: 0, behavior: "smooth" });
		}

		this.solicitationStepsContainerRef?.nativeElement.scrollTo({ top: 0, behavior: "smooth" });
	}

	private setCustomComment(type: string): void {
		this.hasSpecificActionTag = true;
		this.comment.setValue(SolicitationCommentTypes.tagRecord[type]);
		this.comment.markAsDirty();
		this.commentType.setValue(type);
	}

	private resetFormWithDefaultValues(): void {
		this.solicitationStepForm.reset({
			comment: this.commentDefaultValue,
			commentType: this.commentTypeDefaultValue,
		});
	}
}
