import {
	AfterContentInit,
	ChangeDetectionStrategy,
	Component,
	ContentChildren,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
	QueryList,
	ViewChild,
} from "@angular/core";
import { SwiperContainer } from "swiper/element";
import { Swiper, SwiperOptions } from "swiper/types";
import { CarouselSlideDirective } from "./carousel-slide/carousel-slide.directive";

@Component({
	selector: "cp-carousel",
	templateUrl: "./carousel.component.html",
	styleUrl: "./carousel.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements OnDestroy, AfterContentInit {
	@Input() config?: SwiperOptions = {
		pagination: true,
		on: {
			realIndexChange: (swiper) => {
				this.slideChangedEvent.emit(swiper.realIndex);
			},
		},
		injectStyles: [
			`
			:host .swiper {
			  overflow: initial;
			}
			:host .swiper-pagination {
				bottom: -1.375rem;
			}
			:host .swiper-backface-hidden ::slotted(swiper-slide.swiper-slide-prev) {
				transform:translateX(-1.5rem);
			}
			:host .swiper-backface-hidden ::slotted(swiper-slide.swiper-slide-next) {
				transform:translateX(1.5rem);
			}
			`,
		],
	};

	@Output() slideChangedEvent = new EventEmitter<number>();

	@ContentChildren(CarouselSlideDirective) slides?: QueryList<CarouselSlideDirective>;
	@ViewChild("swiperRef") swiperRef?: ElementRef<SwiperContainer>;

	private swiper?: Swiper;

	get activeIndex() {
		return this.swiperRef?.nativeElement.swiper.realIndex;
	}

	get isEnd() {
		return this.swiperRef?.nativeElement.swiper.isEnd;
	}

	get length() {
		return this.swiperRef?.nativeElement.swiper.slides.length;
	}

	ngAfterContentInit() {
		this.initSwiper();
	}

	ngOnDestroy() {
		this.swiper?.destroy();
	}

	private initSwiper() {
		this.swiper = this.swiperRef?.nativeElement.swiper;
	}

	slideNext() {
		this.swiperRef?.nativeElement.swiper.slideNext(100);
	}

	slideTo(index: number) {
		this.swiperRef?.nativeElement.swiper.slideTo(index, 100);
	}

	slidePrev() {
		this.swiperRef?.nativeElement.swiper.slidePrev(100);
	}
}
