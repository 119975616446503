import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { RoundedButtonModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/buttons/rounded-button";
import { EthIconComponent } from "@e-tenant-hub/shared/ui/icon";
import { ResponsiveModalContainerService } from "@e-tenant-hub/shared/ui/responsive/modal-container";

@Component({
	selector: "cp-common-modal-container",
	standalone: true,
	imports: [CommonModule, EthIconComponent, RoundedButtonModule],
	templateUrl: "./common-modal-container.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonModalContainerComponent {
	@Input() title?: string;
	@Input() imgSrc?: string;
	@Input() cancelButtonLabel?: string;
	@Input() cancelButtonDisabled = false;
	@Input() confirmButtonLabel?: string;
	@Input() confirmButtonDisabled = false;
	@Input() showCloseButton = true;

	@Output() readonly confirmButtonClicked = new EventEmitter<void>();

	private modalService = inject(ResponsiveModalContainerService);

	closeDialog(): void {
		this.modalService.closeDialog();
	}

	handleConfirmButtonClick(): void {
		this.confirmButtonClicked.emit();
	}
}
