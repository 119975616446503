import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";
import { SwiperContainer, register } from "swiper/element/bundle";
import { SwiperOptions } from "swiper/types";

@Directive({
	selector: "[ethSwiper]",
	standalone: true,
})
export class EthSwiperDirective implements AfterViewInit {
	@Input() config?: SwiperOptions;

	constructor(private el: ElementRef<SwiperContainer>) {}

	ngAfterViewInit() {
		register();

		Object.assign(this.el.nativeElement, this.config);
		this.el.nativeElement.initialize();
	}
}
