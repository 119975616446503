<cp-common-modal-container
	[title]="'Contact'"
	[confirmButtonLabel]="'Appeler'"
	(confirmButtonClicked)="callContact(data.phoneNumber)"
>
	<div class="relative w-full overflow-auto rounded-2xl p-4">
		<cp-common-background class="sm:z-0" [withMoreOpacity]="true"></cp-common-background>
		<div class="relative inline-flex w-full flex-col items-center justify-center gap-2 sm:!z-[1]">
			<eth-icon
				class="text-primary rounded-full bg-white p-3 text-2xl"
				[name]="'call'"
				[filled]="true"
			></eth-icon>
			<div class="flex flex-col items-center justify-center gap-1 self-stretch">
				<div class="font-futura self-stretch text-center text-base font-medium text-neutral-800">
					{{ data.title }}
				</div>
				<div class="font-futura self-stretch text-center text-sm font-medium text-zinc-600">
					{{ data.subtitle }}
				</div>
			</div>
		</div>
	</div>
</cp-common-modal-container>
