<form class="" [formGroup]="form">
	<div class="flex w-full flex-col items-center justify-center gap-6">
		<div class="flex w-full flex-col items-center justify-center gap-3">
			<div class="font-futura self-stretch text-sm font-medium text-neutral-800">
				Pour vous permettre de bénéficier des services proposés par le site, tels qu'optimiser son utilisation,
				il est conseillé d'activer les cookies ci-dessous puis de valider votre choix.
			</div>
			<div class="flex w-full flex-col items-center justify-center gap-3">
				<div class="inline-flex w-full gap-3">
					<cp-rounded-button
						class="w-1/2"
						[isReversed]="true"
						[label]="'Tout refuser'"
						(clicked)="btnRefuseAllClick()"
					></cp-rounded-button>
					<cp-rounded-button
						class="w-1/2"
						[label]="'Tout accepter'"
						(clicked)="btnAcceptAllClick()"
					></cp-rounded-button>
				</div>
				<a
					class="text-primary font-futura cursor-pointer text-sm font-medium underline"
					href="/cookies-policy"
					target="_blank"
					>Données personnelles et cookies</a
				>
			</div>
		</div>
		<div class="h-px self-stretch bg-zinc-200"></div>
		<div class="flex w-full flex-col items-center justify-center gap-3">
			<div class="font-futura self-stretch text-sm font-medium text-neutral-800">
				Ces cookies sont strictement nécessaires au bon fonctionnement du site ou à la fourniture d'un service
				que vous avez expressément demandé. Ils ne sont pas soumis à votre consentement. Nous utilisons de tels
				cookies pour mémoriser vos choix.
			</div>
			<cp-toggle-input
				[isDisabled]="true"
				formControlName="refuseNecessaryCookies"
				unselectedLabel="accepter"
				selectedLabel="refuser"
			></cp-toggle-input>
		</div>
		<div class="flex w-full flex-col items-center justify-center gap-3">
			<div class="flex w-full flex-col items-center justify-center gap-1">
				<div class="font-futura self-stretch text-base font-medium text-neutral-800">Mesure d'audience</div>
				<div class="font-futura self-stretch text-sm font-medium text-neutral-800">
					Google Analytics (universal) - Ce service peut déposer 3 cookies.
				</div>
				<cp-toggle-input
					formControlName="refuseGoogleAnalyticsCookies"
					unselectedLabel="accepter"
					selectedLabel="refuser"
				></cp-toggle-input>
			</div>
		</div>
		<div class="flex w-full flex-col items-center justify-center gap-3">
			<cp-rounded-button class="w-full" [label]="'Enregistrer'" (clicked)="btnSaveClick()"></cp-rounded-button>
		</div>
	</div>
</form>
