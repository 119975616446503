import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { EthBaseControlInputComponent } from "@e-tenant-hub/shared/ui/inputs/base-control-input";
import { MaskitoMasks } from "@e-tenant-hub/shared/utils";
import { MaskitoOptions } from "@maskito/core";

@Component({
	selector: "cp-common-input",
	templateUrl: "./common-input.component.html",
	styleUrl: "./common-input.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonInputComponent extends EthBaseControlInputComponent {
	@Input() type: "email" | "text" | "password" | "number" | "search" | "tel" = "text";
	@Input() specialType?: "amount";
	@Input() iconColor?: "text-primary" | "text-neutral-400" = "text-neutral-400";
	@Input() isReadOnly = false;
	@Input() cursor: "auto" | "pointer" = "auto";

	@Input() get prefixIcon(): string | undefined {
		if (this.type === "search") {
			this.prefixIcon = "search";
			this.iconColor = "text-neutral-400";
		}
		return this._prefixIcon;
	}
	set prefixIcon(value: string) {
		this._prefixIcon = value;
	}

	@Input() get suffixIcon(): string | undefined {
		if (this.specialType === "amount") {
			this.suffixIcon = "euro";
			this.iconColor = "text-primary";
		}
		return this._suffixIcon;
	}
	set suffixIcon(value: string) {
		this._suffixIcon = value;
	}

	get errorCount(): number {
		return this.ngControl.errors ? Object.keys(this.ngControl.errors).length : 0;
	}

	private _prefixIcon?: string;
	private _suffixIcon?: string;

	isPasswordVisible = false;
	hasFocus = false;

	get mask(): MaskitoOptions | null {
		if (this.type === "tel") {
			return MaskitoMasks.phoneNumber.options;
		}

		if (this.specialType === "amount") {
			return MaskitoMasks.amount.options;
		}

		return null;
	}

	onFocus() {
		this.hasFocus = true;
	}

	onBlur() {
		this.hasFocus = false;
	}

	changePasswordVisibility() {
		this.isPasswordVisible = !this.isPasswordVisible;
		this.type = this.isPasswordVisible ? "text" : "password";
	}
}
