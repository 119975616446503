import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EthIconComponent } from "@e-tenant-hub/shared/ui/icon";
import { StampedIconComponent } from "./stamped-icon.component";

@NgModule({
	imports: [CommonModule, EthIconComponent],
	declarations: [StampedIconComponent],
	exports: [StampedIconComponent],
})
export class StampedIconModule {}
