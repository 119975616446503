import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatChipsModule } from "@angular/material/chips";
import { EthIconComponent } from "@e-tenant-hub/shared/ui/icon";
import { ChipComponent } from "./chip.component";

@NgModule({
	imports: [CommonModule, MatChipsModule, EthIconComponent],
	declarations: [ChipComponent],
	exports: [ChipComponent],
})
export class ChipModule {}
