import { ChangeDetectionStrategy, Component, OnDestroy, inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NotificationsService } from "@e-tenant-hub/client-platform/shared/ui/responsive/notifications";
import { AuthService } from "@e-tenant-hub/shared/auth";
import { UpdatePasswordRequest, UsersService } from "@e-tenant-hub/shared/rentals";
import { ResponsiveModalContainerService } from "@e-tenant-hub/shared/ui/responsive/modal-container";
import { CommonValidators } from "@e-tenant-hub/shared/utils";
import { Subscription, catchError, of } from "rxjs";

@Component({
	selector: "cp-password-form-modal",
	templateUrl: "./password-form-modal.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordFormModalComponent implements OnDestroy {
	private readonly formBuilder = inject(FormBuilder);
	private readonly modalContainerService = inject(ResponsiveModalContainerService);
	private readonly notificationsService = inject(NotificationsService);
	private readonly usersService = inject(UsersService);
	private readonly authService = inject(AuthService);
	private readonly user = this.authService.user;

	form!: FormGroup;
	private submitSubscription!: Subscription;

	constructor() {
		this.createForm();
	}

	ngOnDestroy(): void {
		this.submitSubscription?.unsubscribe();
	}

	clearFormStatus(): void {
		this.form.markAsUntouched();
		this.form.markAsPristine();
	}

	createForm() {
		this.form = this.formBuilder.group({
			currentPassword: ["", [Validators.required]],
			newPassword: ["", [Validators.required, CommonValidators.passwordsComplexity()]],
			newPasswordConfirm: ["", [Validators.required, CommonValidators.matchingValues("newPassword")]],
		});
	}

	submit() {
		if (!this.form.valid) return;

		const updateRequest = {
			email: this.user.username,
			password: this.form.value.newPassword,
			previousPassword: this.form.value.currentPassword,
		} as UpdatePasswordRequest;

		this.submitSubscription = this.usersService
			.updatePassword(this.user.username, updateRequest)
			.pipe(
				catchError(() => {
					this.notificationsService.showErrorMessage(
						"Le changement de mot de passe a échoué, veuillez vérifier les informations saisies ou réessayer ultérieurement."
					);
					return of(undefined);
				})
			)
			.subscribe((response) => {
				if (response === null) {
					this.clearFormStatus();
					this.notificationsService.showConfirmationMessage(
						"Votre mot de passe a bien été modifié, un mail de confirmation vous a été envoyé."
					);
					this.modalContainerService.closeDialog();
				}
			});
	}
}
