import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { EthBaseControlInputComponent } from "@e-tenant-hub/shared/ui/inputs/base-control-input";
import { RadioInputOption } from "./radio-input-option/radio-input-option.type";

@Component({
	selector: "cp-radio-input",
	templateUrl: "./radio-input.component.html",
	styleUrl: "./radio-input.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioInputComponent extends EthBaseControlInputComponent {
	@Input() options: RadioInputOption[] = [];
}
