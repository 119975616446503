import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatRippleModule } from "@angular/material/core";
import { EthIconComponent } from "@e-tenant-hub/shared/ui/icon";
import { LinkButtonComponent } from "./link-button.component";

@NgModule({
	imports: [CommonModule, MatRippleModule, EthIconComponent],
	declarations: [LinkButtonComponent],
	exports: [LinkButtonComponent],
})
export class LinkButtonModule {}
