import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { ResponsiveModalContainerService } from "@e-tenant-hub/shared/ui/responsive/modal-container";
import { CallContactModalData } from "./call-contact-modal.type";

@Component({
	selector: "cp-call-contact-modal",
	templateUrl: "./call-contact-modal.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallContactModalComponent {
	private readonly modalContainerService = inject(ResponsiveModalContainerService);

	data: CallContactModalData;

	constructor() {
		this.data = this.modalContainerService.getDialogData() as CallContactModalData;
	}

	callContact(phoneNumber: string): void {
		window.location.href = "tel:" + phoneNumber;
		this.modalContainerService.closeDialog();
	}
}
