<cp-common-modal-container
	class="text-center"
	#modalContainer
	[cancelButtonLabel]="!showStepper && (!data.isMandatory || user.isImpersonated) ? 'Passer' : ''"
	[confirmButtonLabel]="!showStepper ? 'Répondre' : ''"
	[showCloseButton]="showStepper && (!data.isMandatory || user.isImpersonated)"
	[title]="showStepper && !isLastCarouselSlide ? data.title : ''"
	(confirmButtonClicked)="showStepper = true"
>
	<div class="grid w-full grid-flow-row-dense grid-cols-1">
		@if (!showStepper) {
			<cp-form-template-default-start-message [title]="data.title" [description]="data.description">
			</cp-form-template-default-start-message>
		}

		@if (showStepper) {
			<cp-carousel #carouselCpt [config]="swiperOptions">
				@for (question of data.questions; track question.id) {
					<ng-container *cpCarouselSlide>
						<div [formGroup]="form">
							<form>
								<div class="flex flex-col justify-center gap-3 self-stretch text-left">
									<div>{{ question.title }}</div>
									<div class="w-full">
										@switch (question.type) {
											@case (questionTypes.SingleChoice) {
												<cp-form-template-single-choice
													[question]="question"
													[form]="getFormGroupById(question.id)"
												></cp-form-template-single-choice>
											}
											@case (questionTypes.FreeAnswer) {
												<cp-form-template-free-answer
													[question]="question"
													[form]="getFormGroupById(question.id)"
												>
												</cp-form-template-free-answer>
											}
											@case (questionTypes.MultipleChoice) {
												<cp-form-template-multiple-choice
													[propositions]="question.propositions"
													[form]="getFormGroupById(question.id)"
												>
												</cp-form-template-multiple-choice>
											}
										}
									</div>
								</div>
							</form>
						</div>
					</ng-container>
				}

				<ng-container *cpCarouselSlide>
					<cp-form-template-default-end-message></cp-form-template-default-end-message>
				</ng-container>
				<ng-container *cpCarouselSlide>
					<cp-form-template-default-validation-message
						[title]="data.title"
					></cp-form-template-default-validation-message>
				</ng-container>
			</cp-carousel>

			@if (!isEndOfQuestions()) {
				<div class="flex w-full flex-row" [ngClass]="isFirstQuestion() ? 'justify-end' : 'justify-between'">
					@if (!isFirstQuestion()) {
						<cp-rounded-button [icon]="'arrow_left_alt'" (clicked)="lastQuestion()"> </cp-rounded-button>
					}
					<cp-rounded-button
						[icon]="'arrow_right_alt'"
						[disabled]="!isCurrentQuestionAnswered()"
						(clicked)="nextQuestion()"
					>
					</cp-rounded-button>
				</div>
			}

			@if (isEndOfCarousel() && !isLastCarouselSlide) {
				<div class="mt-6 inline-flex w-full items-center justify-center gap-3">
					<cp-rounded-button
						[class]="'w-1/2'"
						[label]="'Modifier'"
						[isReversed]="true"
						(clicked)="carousel?.slideTo(0); currentQuestionIndex = 0"
					></cp-rounded-button>
					<cp-rounded-button [class]="'w-1/2'" [label]="'Valider'" (clicked)="submit()"></cp-rounded-button>
				</div>
			}

			@if (isLastCarouselSlide) {
				<div class="mt-6 inline-flex w-full items-center justify-center">
					<cp-rounded-button
						[class]="'w-full'"
						[label]="'Accéder à mon espace'"
						(clicked)="closeDialog()"
					></cp-rounded-button>
				</div>
			}
		}
	</div>
</cp-common-modal-container>
