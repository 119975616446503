<mat-chip
	class="!bg-opacity-20"
	[ngClass]="{
		'!bg-primary': color === Colors.Primary,
		'!bg-success': color === Colors.Success,
		'!bg-neutral-400': color === Colors.Neutral400,
		'!rounded': isRemovable,
		'py-1.5': !isRemovable,
	}"
	[removable]="isRemovable"
	[disableRipple]="!isRemovable"
	[disabled]="!isRemovable"
	(removed)="handleRemoveEvent()"
>
	<span
		class="font-futura text-sm font-medium"
		[ngClass]="{
			'text-primary': color === Colors.Primary,
			'text-success': color === Colors.Success,
			'text-neutral-400': color === Colors.Neutral400,
		}"
	>
		{{ label }}
	</span>
	@if (isRemovable) {
		<eth-icon
			[name]="'cancel'"
			[filled]="true"
			[ngClass]="{
				'!text-primary': color === Colors.Primary,
				'!text-success': color === Colors.Success,
				'!text-neutral-400': color === Colors.Neutral400,
			}"
			matChipRemove
		></eth-icon>
	}
</mat-chip>
