import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { EthIconComponent } from "@e-tenant-hub/shared/ui/icon";
import { ResponsiveModalContainerService } from "@e-tenant-hub/shared/ui/responsive/modal-container";

@Component({
	selector: "cp-complex-modal-container",
	standalone: true,
	imports: [CommonModule, EthIconComponent],
	templateUrl: "./complex-modal-container.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComplexModalContainerComponent {
	@Input() title?: string;
	@Input() description?: string;
	@Input() hasReminderButton = false; // TODO: Création de bouton "dynamique"
	@Input() hasCancellationButton = false; // TODO: Création de bouton "dynamique"

	@Output() readonly reminderButtonClicked = new EventEmitter<void>(); // TODO: Création de bouton "dynamique"
	@Output() readonly cancellationButtonClicked = new EventEmitter<void>(); // TODO: Création de bouton "dynamique"

	private readonly modalService = inject(ResponsiveModalContainerService);

	handleReminderButtonClick(): void {
		if (this.hasReminderButton) {
			this.reminderButtonClicked.emit();
		}
	}

	handleCancellationButtonClick(): void {
		if (this.hasCancellationButton) {
			this.cancellationButtonClicked.emit();
		}
	}

	closeDialog(): void {
		this.modalService.closeDialog();
	}
}
