import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
	selector: "cp-stamped-icon",
	templateUrl: "./stamped-icon.component.html",
	styleUrl: "./stamped-icon.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StampedIconComponent {
	@Input() mainIcon!: string;
	@Input() mainIconfilled = false;
	@Input() stampedIcon?: "check_circle" | "canceled";
}
