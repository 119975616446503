<cp-complex-modal-container
	[title]="'Mes demandes / En cours'"
	[description]="'Créée le ' + (data.creationDate | preciseDate: 'DD.MM.YY')"
	[hasCancellationButton]="data.isClosureRequestAllowed && data.canInteract"
	[hasReminderButton]="data.isReminderAllowed && data.canInteract"
	(reminderButtonClicked)="setReminderAction()"
	(cancellationButtonClicked)="setCancellationAction()"
>
	<div
		class="min-h-solicitation grid gap-0 overflow-auto lg:grid-cols-5 lg:overflow-visible xl:grid-cols-3"
		#solicitationDetailContainer
	>
		<!-- Description area -->
		<div class="grid gap-6 sm:col-span-3 lg:col-span-3 xl:col-span-2">
			<div
				class="inline-flex w-full flex-col items-start justify-start gap-6 bg-white p-6 sm:rounded-bl-2xl sm:p-12"
			>
				<div class="order-1 flex flex-col items-start justify-center gap-4">
					<cp-chip
						[label]="solicitationStatus.record[data.status]"
						[color]="solicitationsService.determineSolicitationStatusColor(data.status)"
					>
					</cp-chip>
					<div class="font-intro text-lg font-bold uppercase text-neutral-800">
						{{ data.title }}
					</div>
				</div>

				<!-- Details -->
				<div class="order-3 hidden items-start justify-start gap-12 bg-white sm:order-2 sm:inline-flex">
					<ng-template [ngTemplateOutlet]="solicitationDetails"></ng-template>
				</div>
				<cp-expansion-panel class="order-3 sm:order-2 sm:hidden" [title]="'Détails'">
					<div class="inline-flex items-start justify-start gap-4">
						<ng-template [ngTemplateOutlet]="solicitationDetails"></ng-template>
					</div>
				</cp-expansion-panel>

				<ng-template #solicitationDetails>
					<div class="inline-flex flex-col items-start justify-start gap-2">
						<div class="font-futura text-sm font-medium text-neutral-800">Périmètre</div>
						<div class="font-futura text-sm font-medium text-neutral-400">
							{{
								solicitationScopes.partRecord[
									data.isPublic ? solicitationScopes.public : solicitationScopes.private
								]
							}}
						</div>
					</div>
					<div class="inline-flex flex-col items-start justify-start gap-2">
						<div class="font-futura text-sm font-medium text-neutral-800">Catégorie</div>
						<div class="font-futura text-sm font-medium text-neutral-400">
							{{ solicitationCategories.record[data.category] }}
						</div>
					</div>
					<div class="inline-flex flex-col items-start justify-start gap-2">
						<div class="font-futura text-sm font-medium text-neutral-800">N°</div>
						<div class="font-futura text-sm font-medium text-neutral-400">{{ data.id }}</div>
					</div>
				</ng-template>
				<!-- Details -->
				@if (data.mainComment) {
					<div
						class="order-2 inline-flex items-center justify-center gap-2.5 self-stretch rounded-lg border border-zinc-200 px-4 py-3 sm:order-3"
					>
						<div class="font-futura shrink grow basis-0 text-base font-medium text-zinc-600">
							{{ data.mainComment }}
						</div>
					</div>
				}
				<div class="order-3 inline-flex items-center justify-center gap-2.5 self-stretch px-4 py-3 sm:order-3">
					<div class="font-futura shrink grow basis-0 text-sm font-medium text-zinc-600">
						Si vous avez des documents à transmettre, merci de passer par
						<a class="text-primary" href="mailto:crc@est-metropole-habitat.fr"
							>crc&#64;est-metropole-habitat.fr</a
						>
						en indiquant votre numéro de locataire et le numéro de dossier.
					</div>
				</div>

				<!-- Contacts -->
				@if (data.companyName) {
					<div class="order-4 flex flex-col justify-start gap-2 self-stretch">
						<!-- TODO: @NMA - Create custom tab group ui component -->
						<mat-tab-group
							class="w-full gap-3 sm:gap-6"
							mat-stretch-tabs="false"
							mat-align-tabs="start"
							dynamicHeight
							disablePagination
							fitInkBarToContent
							disableRipple
						>
							<mat-tab label="Contacts">
								<div class="flex flex-col items-start justify-start self-stretch">
									<div
										class="inline-flex items-start justify-start gap-2.5 self-stretch rounded-tl-lg rounded-tr-lg border border-zinc-200 px-4 py-3"
									>
										<div class="font-futura text-sm font-medium text-neutral-800 sm:text-base">
											{{ data.companyName | titlecase }}
										</div>
									</div>
									<div
										class="flex flex-col items-start justify-start gap-4 self-stretch rounded-bl-lg rounded-br-lg border-b border-l border-r border-zinc-200 px-4 py-3"
									>
										@if (companyPhoneNumber) {
											<div class="inline-flex items-center justify-start gap-2">
												<eth-icon
													class="relative text-[19px] text-zinc-600 sm:text-[21px]"
													[name]="'call'"
													[filled]="true"
												></eth-icon>
												<a
													class="font-futura text-sm font-medium text-zinc-600 sm:text-base"
													[href]="companyPhoneNumber"
												>
													{{ companyPhoneNumber }}
												</a>
											</div>
										}
										@if (data.companyMail) {
											<div class="inline-flex items-center justify-start gap-2">
												<eth-icon
													class="relative text-[19px] text-zinc-600 sm:text-[21px]"
													[name]="'alternate_email'"
													[filled]="true"
												></eth-icon>
												<a
													class="font-futura text-sm font-medium text-zinc-600 sm:text-base"
													[href]="data.companyMail"
												>
													{{ data.companyMail }}
												</a>
											</div>
										}
									</div>
								</div>
							</mat-tab>
						</mat-tab-group>
					</div>
				}
			</div>
		</div>
		<!-- Comment area -->
		<div class="relative grid sm:col-span-3 lg:col-span-2 lg:grid-cols-1 lg:rounded-br-2xl xl:col-span-1">
			<cp-common-background class="sm:!z-0"></cp-common-background>
			<div class="inline-flex w-full flex-col items-start justify-between py-4 pl-4 sm:!z-[1] sm:py-6 sm:pl-6">
				<div
					class="max-h-comments scrollbar-thin scrollbar-thumb-primary/30 scrollbar-track-white flex flex-col items-end gap-3 self-stretch overflow-y-auto pr-4 sm:gap-4 sm:pr-6"
					#solicitationStepsContainer
				>
					@for (step of data.solicitationSteps; track step.id) {
						@if (step.comment || step.information) {
							@if (step.isRequestedByCurrentRentalContract) {
								@if (solicitationCommentTypes.iconRecord[step.commentType]) {
									<cp-chat-bubble
										[date]="step.creationDate"
										[position]="'right'"
										[footer]="step.comment"
										[footerIcon]="solicitationCommentTypes.iconRecord[step.commentType]"
										[footerColor]="Colors.Zinc600"
									></cp-chat-bubble>
								} @else {
									<cp-chat-bubble
										[date]="step.creationDate"
										[header]="'Vous'"
										[position]="'right'"
										[content]="step.comment"
									></cp-chat-bubble>
								}
							} @else {
								<cp-chat-bubble
									[date]="step.creationDate"
									[content]="step.comment"
									[header]="'Est Métropole Habitat'"
									[position]="'left'"
									[footer]="step.information"
									[footerIcon]="'info'"
									[footerColor]="Colors.Primary"
								></cp-chat-bubble>
							}
						}
					}
				</div>
				<div class="hidden w-full pr-6 lg:inline-flex">
					<ng-template [ngTemplateOutlet]="commentFormTemplate"></ng-template>
				</div>
			</div>
		</div>
	</div>
	<div class="w-full lg:hidden">
		<ng-template [ngTemplateOutlet]="commentFormTemplate"></ng-template>
	</div>
</cp-complex-modal-container>

<ng-template #commentFormTemplate>
	@if (solicitationStatus.inProgressCategoryItems.includes(data.status)) {
		<div class="w-full rounded-lg bg-white px-4 py-3" (click)="showCommentDeactivationReason()">
			<form
				class="inline-flex w-full items-center gap-2 align-middle"
				[ngClass]="{
					'justify-between': hasSpecificActionTag,
				}"
				[formGroup]="solicitationStepForm"
				(ngSubmit)="onSubmit(data.id)"
			>
				@if (hasSpecificActionTag) {
					<cp-chip
						[label]="comment.value"
						[isRemovable]="true"
						[color]="
							commentType.value === solicitationCommentTypes.closureRequest
								? Colors.Success
								: Colors.Primary
						"
						(onRemoved)="removeSpecificActionTag()"
					></cp-chip>
				} @else {
					<cp-textarea-input
						class="w-full"
						[placeholder]="comment.enabled ? 'Écrivez un commentaire...' : 'Envoi de commentaire désactivé'"
						[fill]="'empty'"
						[maxLength]="commentMaxLength"
						[minRows]="1"
						[displayHintType]="'maxLengthReached'"
						formControlName="comment"
					></cp-textarea-input>
				}
				<cp-rounded-button
					class="hidden 2xl:block"
					[label]="'Envoyer'"
					[disabled]="isFormSubmitButtonDisabled"
					[type]="'submit'"
					[isFullWidth]="false"
				></cp-rounded-button>
				<cp-rounded-button
					class="block 2xl:hidden"
					[disabled]="isFormSubmitButtonDisabled"
					[type]="'submit'"
					[icon]="'send'"
					[isFullWidth]="false"
				></cp-rounded-button>
			</form>
		</div>
	}
</ng-template>
