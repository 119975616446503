import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { NotificationsService } from "@e-tenant-hub/client-platform/shared/ui/responsive/notifications";
import { FilesService } from "@e-tenant-hub/shared/rentals";
import { ResponsiveModalContainerService } from "@e-tenant-hub/shared/ui/responsive/modal-container";
import saveAs from "file-saver";

@Component({
	selector: "cp-direct-debit-modal",
	templateUrl: "./direct-debit-modal.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DirectDebitModalComponent {
	readonly filesService = inject(FilesService);
	private readonly modalContainerService = inject(ResponsiveModalContainerService);
	private readonly notificationsService = inject(NotificationsService);

	downloadSEPAForm(): void {
		this.filesService.getSEPAForm().subscribe((blob: Blob) => {
			if (blob) {
				saveAs(blob, "emh_mandat_de_prelevement_sepa.pdf");
				this.notificationsService.showMessage("Téléchargement effectué", "download", true);
				this.modalContainerService.closeDialog();
			}
		});
	}
}
