<div
	class="flex w-5/6 flex-col items-start justify-center gap-1"
	[ngClass]="{
		'self-end': position === 'right',
		'self-start': position === 'left',
	}"
>
	@if (date) {
		<div
			class="font-futura text-xs font-medium text-neutral-400"
			[ngClass]="{
				'self-end': position === 'right',
				'self-start': position === 'left',
			}"
		>
			{{ date | preciseDate: "D MMMM YYYY" }}
		</div>
	}
	<div class="flex w-full flex-col items-start justify-center">
		@if (content && header) {
			<!-- Header -->
			<div
				class="inline-flex w-full items-center justify-between rounded-tl-lg rounded-tr-lg bg-white bg-opacity-40 px-4 py-3"
			>
				<div class="font-futura text-sm font-medium text-neutral-800">{{ header }}</div>
			</div>

			<!-- Content -->
			<div
				class="inline-flex w-full items-center justify-center bg-white bg-opacity-60 px-4 py-3"
				[ngClass]="{
					'rounded-br-lg': !footer && position === 'left',
					'rounded-bl-lg': !footer && position === 'right',
				}"
			>
				<div
					class="font-futura w-full shrink grow basis-0 break-words text-sm font-medium text-zinc-600 sm:text-base"
				>
					{{ content }}
				</div>
			</div>
		}
		@if (footer) {
			<!-- Footer -->
			<div
				class="inline-flex w-full items-center justify-between bg-white bg-opacity-40 px-4 py-3"
				[ngClass]="{
					'rounded-bl-lg': position === 'right',
					'rounded-br-lg': position === 'left',
					'rounded-tl-lg rounded-tr-lg': !content,
				}"
			>
				<div class="flex shrink grow basis-0 items-center justify-start gap-2">
					@if (footerIcon) {
						<eth-icon
							class="relative text-[19px]"
							[ngClass]="{
								'text-primary': footerColor === Colors.Primary,
								'text-zinc-600': footerColor === Colors.Zinc600,
							}"
							[name]="footerIcon"
							[filled]="true"
						></eth-icon>
					}
					<div
						class="font-futura w-full break-words text-sm font-medium sm:text-base"
						[ngClass]="{
							'text-primary': footerColor === Colors.Primary,
							'text-zinc-600': footerColor === Colors.Zinc600,
						}"
					>
						{{ footer }}
					</div>
				</div>
			</div>
		}
	</div>
</div>
