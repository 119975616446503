import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { EthIconComponent } from "@e-tenant-hub/shared/ui/icon";
import { MaskitoDirective } from "@maskito/angular";
import { CommonInputComponent } from "./common-input.component";

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, MatInputModule, EthIconComponent, MaskitoDirective],
	declarations: [CommonInputComponent],
	exports: [CommonInputComponent],
})
export class CommonInputModule {}
