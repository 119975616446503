import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, inject } from "@angular/core";
import { EthBaseControlInputComponent } from "@e-tenant-hub/shared/ui/inputs/base-control-input";
import { MaskitoMasks, PreciseDate, ScreenSizeService } from "@e-tenant-hub/shared/utils";
import { Subscription } from "rxjs";

@Component({
	selector: "cp-date-picker-input",
	templateUrl: "./date-picker-input.component.html",
	styleUrl: "./date-picker-input.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerInputComponent extends EthBaseControlInputComponent implements OnInit, OnDestroy {
	@Input() minDate?: PreciseDate;
	@Input() maxDate?: PreciseDate;

	private screenSizeService = inject(ScreenSizeService);
	private changeDetectorRef = inject(ChangeDetectorRef);

	mask = MaskitoMasks.date.options;

	isSmallScreen?: boolean;
	screenSizeSubscription?: Subscription;
	hasFocus = false;

	constructor() {
		super();
	}

	ngOnInit(): void {
		this.screenSizeSubscription = this.screenSizeService
			.observeSmallScreen()
			.subscribe((isSmallScreen: boolean) => {
				this.isSmallScreen = isSmallScreen;
				this.changeDetectorRef.detectChanges();
			});
	}

	ngOnDestroy(): void {
		this.screenSizeSubscription?.unsubscribe();
	}

	onFocus() {
		this.hasFocus = true;
	}

	onBlur() {
		this.hasFocus = false;
	}
}
