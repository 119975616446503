import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LinkButtonModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/buttons/link-button";
import { StampedIconModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/stamped-icon";
import { EthIconComponent } from "@e-tenant-hub/shared/ui/icon";
import { BadgeComponent } from "./badge/badge.component";
import { NotificationSnackBarComponent } from "./snack-bar/snack-bar.component";

@NgModule({
	imports: [CommonModule, EthIconComponent, LinkButtonModule, StampedIconModule],
	declarations: [NotificationSnackBarComponent, BadgeComponent],
	exports: [BadgeComponent],
})
export class NotificationsModule {}
