import { ComponentType } from "@angular/cdk/portal";
import { Injectable } from "@angular/core";
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { ResponsiveModalEntity } from "./modal-container.types";

@Injectable({
	providedIn: "root",
})
export abstract class ResponsiveModalContainerService<T> {
	abstract closeDialog(result?: any): void;
	abstract getDialogData(): unknown;
	abstract openDialog(
		component: ComponentType<T>,
		data?: unknown,
		config?: unknown
	): MatBottomSheetRef<T> | MatDialogRef<T> | undefined;
	abstract reloadData(data: unknown): void;
	abstract afterClosed(): Observable<any>;

	protected dialogQueue = new Array<ResponsiveModalEntity<T>>();
}
