import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { RadioInputOption } from "@e-tenant-hub/client-platform/shared/ui/responsive/inputs/radio-input";
import { SelectInputOption } from "@e-tenant-hub/client-platform/shared/ui/responsive/inputs/select-input";
import { AuthService } from "@e-tenant-hub/shared/auth";
import {
	SolicitationCategories,
	SolicitationCreationRequest,
	SolicitationCreationResponse,
	SolicitationScopes,
	SolicitationSubCategories,
	SolicitationsService,
} from "@e-tenant-hub/shared/rentals";
import { ResponsiveModalContainerService } from "@e-tenant-hub/shared/ui/responsive/modal-container";
import { of } from "rxjs/internal/observable/of";
import { catchError } from "rxjs/internal/operators/catchError";

@Component({
	selector: "cp-solicitation-creation-modal",
	templateUrl: "./solicitation-creation-modal.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SolicitationCreationModalComponent {
	private readonly formBuilder = inject(FormBuilder);
	private readonly modalContainerService = inject(ResponsiveModalContainerService);
	private readonly solicitationsService = inject(SolicitationsService);
	private readonly changeDetector = inject(ChangeDetectorRef);
	private readonly authService = inject(AuthService);
	private readonly user = this.authService.user;

	solicitationCategories = SolicitationCategories;

	isFormSubmitDisabled = false;
	selectedCategoryIndex = 0;
	isSolicitationCreated = false;
	commentMaxLength = 288;

	get category(): FormControl {
		return this.solicitationCreationForm.get("category") as FormControl;
	}
	get subCategory(): FormControl {
		return this.solicitationCreationForm.get("subCategory") as FormControl;
	}
	get scope(): FormControl {
		return this.solicitationCreationForm.get("scope") as FormControl;
	}
	get comment(): FormControl {
		return this.solicitationCreationForm.get("comment") as FormControl;
	}

	solicitationCreationForm = this.formBuilder.group({
		category: ["", Validators.required],
		subCategory: [{ value: "", disabled: true }, Validators.required],
		scope: [SolicitationScopes.private, Validators.required],
		comment: ["", [Validators.maxLength(this.commentMaxLength), Validators.required]],
	});

	private categories = SolicitationCategories.all;

	categorySelectOptions: SelectInputOption[] = this.createCategorySelectInputOptions(this.categories);

	subCategorySelectOptions: SelectInputOption[][] = [
		this.createSubCategorySelectInputOptions(SolicitationSubCategories.allRentalManagement),
		this.createSubCategorySelectInputOptions(SolicitationSubCategories.allTechnical),
		this.createSubCategorySelectInputOptions(SolicitationSubCategories.allDisorders),
	];

	scopeRadioOptions: RadioInputOption[] = this.createScopeRadioInputOptions(SolicitationScopes.all);

	constructor() {
		this.category.valueChanges.pipe(takeUntilDestroyed()).subscribe((category: SelectInputOption) => {
			if (category) {
				this.selectedCategoryIndex = this.getSelectedCategoryIndex(category.value);
				this.subCategory.enable();
				return;
			}

			this.subCategory.reset();
			this.subCategory.disable();
		});
	}

	submit(): void {
		if (this.solicitationCreationForm.invalid) return;

		this.isFormSubmitDisabled = true;

		const request: SolicitationCreationRequest = {
			description: this.comment.value,
			isPublic: this.scope.value === SolicitationScopes.public,
			rentalId: this.user.currentRentalId,
			thirdPartyId: this.user.thirdPartyId,
			title: [this.category.value.label, this.subCategory.value.label].join("/"),
		};

		this.solicitationsService
			.createSolicitation(request)
			.pipe(
				catchError((error) => {
					this.isFormSubmitDisabled = false;
					console.error(error);
					return of(undefined);
				})
			)
			.subscribe((response?: SolicitationCreationResponse) => {
				this.isFormSubmitDisabled = false;

				if (response) {
					this.solicitationsService.reloadSolicitations();
					this.isSolicitationCreated = true;
					this.changeDetector.markForCheck();
				}
			});
	}

	closeModal(): void {
		this.modalContainerService.closeDialog();
	}

	private createCategorySelectInputOptions(categories: string[]): SelectInputOption[] {
		return categories.map((item: string) => {
			return {
				label: SolicitationCategories.record[item],
				value: item,
			};
		});
	}

	private createSubCategorySelectInputOptions(subCategories: string[]): SelectInputOption[] {
		return subCategories.map((item: string) => {
			return {
				label: SolicitationSubCategories.record[item],
				value: item,
			};
		});
	}

	private createScopeRadioInputOptions(categories: string[]): RadioInputOption[] {
		return categories.map((item: string) => {
			return {
				label: SolicitationScopes.record[item],
				value: item,
			};
		});
	}

	private getSelectedCategoryIndex(value: string | number): number {
		return this.categories.findIndex((c) => c == value);
	}
}
