<div class="inline-flex h-full w-full flex-col items-end justify-center">
	<div class="inline-flex w-full items-center justify-between gap-4 border-b border-zinc-200 px-6 py-3">
		<div class="flex items-center justify-start gap-4">
			<eth-icon class="!hidden text-[19px] text-neutral-400 sm:!block" [name]="'list_alt'"></eth-icon>
			@if (title) {
				<div class="font-futura hidden text-sm font-medium text-neutral-400 sm:block">{{ title }}</div>
			}
		</div>
		<div class="flex items-center justify-end gap-4">
			@if (description) {
				<div class="font-futura hidden text-sm font-medium text-neutral-400 sm:!block">{{ description }}</div>
			}
			<div class="hidden h-6 w-px rounded-full bg-zinc-200 sm:!block"></div>
			@if (hasReminderButton) {
				<button
					class="rounded-full p-1 hover:bg-zinc-600/5"
					(click)="handleReminderButtonClick()"
					title="Relance de la demande"
				>
					<eth-icon class="text-2xl text-neutral-400" [name]="'notifications'"></eth-icon>
				</button>
			}
			@if (hasCancellationButton) {
				<button
					class="rounded-full p-1 hover:bg-zinc-600/5"
					(click)="handleCancellationButtonClick()"
					title="Demande de clôture"
				>
					<eth-icon class="text-2xl text-neutral-400" [name]="'check_circle'"></eth-icon>
				</button>
			}
			<button class="rounded-full p-1 hover:bg-zinc-600/5" (click)="closeDialog()">
				<eth-icon class="text-2xl text-neutral-400" [name]="'close'"></eth-icon>
			</button>
		</div>
	</div>
	<ng-content></ng-content>
</div>
