<div class="inline-flex w-full flex-col items-start justify-start gap-1">
	@if (label) {
		<label
			class="font-futura text-xs font-medium text-neutral-400 lg:text-sm"
			[class.invalid]="ngControl.invalid && ngControl.touched"
			[class.focused]="hasFocus"
		>
			{{ label }}
		</label>
	}
	<mat-form-field
		[class.solid]="fill === 'solid'"
		[class.translucid]="fill === 'translucid'"
		[class.empty]="fill === 'empty'"
		[class.cursor-pointer]="cursor === 'pointer'"
		appearance="outline"
		subscriptSizing="dynamic"
	>
		@if (prefixIcon) {
			<eth-icon class="text-2xl" [name]="prefixIcon" [filled]="true" [class]="iconColor" matPrefix></eth-icon>
		}
		<input
			class="!font-futura !text-sm !font-medium !text-zinc-600 lg:!text-base"
			[class.cursor-pointer]="cursor === 'pointer'"
			[readonly]="isReadOnly"
			[formControl]="ngControl.control"
			[placeholder]="placeholderOrLabel"
			[maskito]="mask"
			[type]="type"
			(focus)="onFocus()"
			(blur)="onBlur()"
			matInput
		/>
		@if (type === "password" || isPasswordVisible) {
			<eth-icon
				class="text-2xl"
				[name]="isPasswordVisible ? 'visibility_off' : 'visibility'"
				[filled]="true"
				[class]="iconColor"
				(click)="changePasswordVisibility()"
				matSuffix
			></eth-icon>
		}
		@if (suffixIcon) {
			<eth-icon class="text-2xl" [name]="suffixIcon" [filled]="true" [class]="iconColor" matSuffix></eth-icon>
		}
		<mat-error class="font-futura pt-2 text-xs font-medium text-rose-500 lg:text-sm">
			<!-- Generic errors -->
			@if (ngControl.hasError("required")) {
				<span class="inline-flex">Le champ "{{ name ?? label }}" est obligatoire.</span>
			}
			@if (ngControl.hasError("matching") && errorCount === 1) {
				<span class="inline-flex">
					@switch (type) {
						@case ("password") {
							Les mots de passe saisis ne sont pas identiques.
						}
						@case ("email") {
							Les adresses email saisies ne sont pas identiques.
						}
						@default {
							Le champ de confirmation ne correspond pas à la valeur de référence.
						}
					}
				</span>
			}

			<!-- Specific 'email' errors -->
			@if (this.type === "email") {
				@if (ngControl.hasError("email")) {
					<span class="inline-flex">L'email n'est pas valide.</span>
				}
			}

			<!-- Specific 'password' errors -->
			@if (type === "password" || isPasswordVisible) {
				@if (ngControl.hasError("passwordComplexity")) {
					<span class="inline-flex">
						Règles de sécurité : au moins 8 caractères, 1 majuscule, 1 minuscule, 2 chiffres, 1 caractère
						spécial et au maximum 16 caractères.
					</span>
				}
			}

			<!-- Specific 'amount' errors -->
			@if (this.specialType === "amount") {
				@if (ngControl.hasError("min")) {
					<span class="inline-flex">Le montant minimum est de {{ ngControl.getError("min").min }} €.</span>
				}
				@if (ngControl.hasError("max")) {
					<span class="inline-flex">Le montant maximum est de {{ ngControl.getError("max").max }} €.</span>
				}
			}

			<!-- Specific 'phoneNumber' errors -->
			@if (this.type === "tel") {
				@if (ngControl.hasError("format")) {
					<span class="inline-flex">Format du numéro de téléphone non valide.</span>
				}
			}
		</mat-error>
	</mat-form-field>
</div>
