import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { EthIconComponent } from "@e-tenant-hub/shared/ui/icon";
import { UtilsModule } from "@e-tenant-hub/shared/utils";
import { MaskitoDirective } from "@maskito/angular";
import { DatePickerInputComponent } from "./date-picker-input";
import { DatePickerRangeInputComponent } from "./date-picker-range-input/date-picker-range-input.component";
@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatDatepickerModule,
		MatFormFieldModule,
		MatInputModule,
		EthIconComponent,
		UtilsModule,
		MaskitoDirective,
	],
	declarations: [DatePickerInputComponent, DatePickerRangeInputComponent],
	exports: [DatePickerInputComponent, DatePickerRangeInputComponent],
	providers: [{ provide: MAT_DATE_LOCALE, useValue: "fr-FR" }],
})
export class DatePickerInputsModule {}
