<button
	class="text-primary inline-flex items-center justify-end gap-2 self-stretch disabled:opacity-40"
	[disabled]="disabled"
	[matRippleDisabled]="disabled"
	(click)="clicked.emit()"
	matRipple
>
	<div class="font-intro text-sm font-bold leading-6 sm:text-base">{{ label | uppercase }}</div>
	@if (icon) {
		<eth-icon class="text-2xl" [name]="icon"></eth-icon>
	}
</button>
